import { Component, Vue, Prop } from "vue-property-decorator";
import API from "@api";
import { Currency } from "@/types/currency";

@Component
export default class StatisticDeclineRate extends Vue {
  @Prop({ type: [Number, String], default: 0 }) private readonly refreshKey!:
    | number
    | string;
  @Prop({ type: Boolean, default: true }) private readonly active!: boolean;
  @Prop({ type: Array }) private readonly currency?: Currency[];
  @Prop({ type: Array }) private readonly userEmails?: string[];
  @Prop({ type: Array }) private readonly cardIds?: number[];
  @Prop({ type: Array }) private readonly bankIds?: number[];

  private loading = false;
  private declineRate = 0;

  private get declineRateStatus() {
    if (this.declineRate >= 30) {
      return "bad";
    }

    if (this.declineRate >= 15) {
      return "normal";
    }

    return "great";
  }

  private async fetchDeclineRate() {
    this.loading = true;

    try {
      const { declineRate } = await API.statistic.filterStatisticDeclines({
        currency: this.currency,
        userEmails: this.userEmails,
        cardIds: this.cardIds,
        bankIds: this.bankIds,
      });

      this.declineRate = declineRate;
      this.loading = false;
    } catch {
      this.loading = false;
    }
  }

  private mounted() {
    let requestKey = Date.now();

    const fetchDeclineRate = async () => {
      if (this.loading) return;

      const oldDequestKey = requestKey;

      await this.fetchDeclineRate();

      if (requestKey !== oldDequestKey) {
        fetchDeclineRate();
      }
    };

    this.$watch(
      () => {
        return [
          this.currency?.length,
          this.userEmails?.length,
          this.cardIds?.length,
          this.bankIds?.length,
          this.refreshKey,
          this.active,
        ].join("-");
      },
      () => {
        if (!this.active) return;

        requestKey++;

        fetchDeclineRate();
      },
      { immediate: true }
    );
  }
}
